<template>
  <a-modal
    title="存入图片库"
    v-model="visible"
    width="380px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
  >
    <div class="input-line">
      <div style="width: 66px"><span style="color: red">*</span>品牌：</div>
      <a-select
        v-model="form.principalData"
        placeholder="请选择品牌"
        style="flex: 1"
        show-search
        option-filter-prop="children"
        @change="handlePrincipalChange"
      >
        <a-select-option
          v-for="item in principalList"
          :key="item.principalId"
          :value="`${item.principalId},${item.principalName}`"
          >{{ item.principalName }}</a-select-option
        >
      </a-select>
    </div>
    <div class="input-line">
      <div style="width: 65px">文件夹：</div>
      <PicFolderTree
        ref="PicFolderTree"
        style="flex: 1"
        :treeDefaultExpandAll="true"
        v-model="form.folderId"
        @input="
          (folderId) => {
            form.folderId = folderId;
          }
        "
      />
    </div>
    <div class="input-line">
      <div style="width: 65px">车系：</div>
      <a-select v-model="form.seriesData" placeholder="请选择车系" option-filter-prop="children" style="flex: 1">
        <a-select-option v-for="item in seriesList" :key="`${item.id},${item.name}`">{{ item.name }}</a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import PicFolderTree from '@/components/xhsAgencyTool/PicFolderTree.vue';
import api from '../api';

export default {
  name: 'SavePicToStoreModal',
  components: { PicFolderTree },
  data() {
    return {
      principalList: [],
      seriesList: [],
      form: {
        principalData: undefined,
        seriesData: undefined,
        folderId: undefined,
      },
      visible: false,
      saveLoading: false,
      fileUrlList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getBrandList();
    },
    onOpen(fileUrlList) {
      this.fileUrlList = fileUrlList;
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.fileUrlList = [];
      this.seriesList = [];
      this.form = {
        principalData: undefined,
        seriesData: undefined,
        folderId: undefined,
      };
    },
    getBrandList() {
      this.handleRequest(
        'getBrandManageList',
        (data) => {
          this.principalList = data.list;
        },
        { size: 999, page: 1 }
      );
    },
    handlePrincipalChange() {
      this.form.folderId = undefined;
      this.form.seriesData = undefined;
      this.seriesList = [];
      this.$refs.PicFolderTree.getFolderList(this.form.principalData.split(',')[0]);
      this.getSeriesList();
    },
    getSeriesList() {
      if (this.form.principalData) {
        this.handleRequest(
          'getNewTypeList',
          (data) => {
            this.seriesList = data;
          },
          { principalIds: this.form.principalData.split(',')[0] }
        );
      }
    },
    save() {
      if (!this.form.principalData) return this.$message.error('请先选择品牌');
      let folderId = this.form.folderId?.length && this.form.folderId != -1 ? this.form.folderId : undefined;
      let principalId = this.form.principalData.split(',')[0];
      let principalName = this.form.principalData.split(',')[1];
      const params = {
        fileUrlList: this.fileUrlList,
        folderId,
        principalId,
        principalName,
      };
      if (this.form.seriesData) {
        params.vehicleSeriesInfoList = [
          {
            vehicleSeriesId: this.form.seriesData.split(',')[0],
            vehicleSeriesName: this.form.seriesData.split(',')[1],
          },
        ];
      }
      this.saveLoading = true;
      this.handleRequest(
        'uploadPictureToStore',
        () => {
          this.$message.success('上传成功');
          this.onClose();
        },
        params
      ).finally(() => (this.saveLoading = false));
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
          resolve();
        } else {
          this.$message.error(`${message}`);
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
